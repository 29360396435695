import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import probeImg from "../assets/probe.png"

const ArPage = () => (
  <Layout>
    <SEO title="Home" />
    <main className="vh-100 flex justify-center items-center">
          
        <a rel="ar" href="https://calypsuexperiments.s3.ap-south-1.amazonaws.com/probe.usdz" target="_blank"> 
            <img src={probeImg} style={{maxWidth: "80%"}} />
        </a>
    
    </main>
  </Layout>
)

export default ArPage
